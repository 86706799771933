<template>
  <div class="container">
    <div class="bread">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item class="highlight">名师</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-divider></el-divider>
    <div>
      <div class="layout">
        <Card :teachersList="teachersList" class="Grid"></Card>
      </div>
      <div class="Pager">
        <!-- 分页 -->
        <el-pagination
          class="center"
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="totle"
          prev-text="上一页"
          next-text="下一页"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./components/Card";
import commonApi from "@/api/common";
import { getAssetsFile } from "@/scripts/utils";
// import { loadData } from "../../../models/teacher";
export default {
  components: {
    Card,
  },
  data() {
    return {
      teachersList: [],
      pageSize: 0,
      totle: 0,
    };
  },
  created() {
    this.loadAssets(0);
  },
  methods: {
    // 添加假数据
    async loadAssets(page) {
      if (page < 4) {
        let assetsList = [];
        const res = await commonApi.GetDocData("teacher");
        if (res && res.code === 200) {
          assetsList = res.data.list.map((item) => {
            const suffix = item.imgSrc.split(".")[1];
            return {
              ...item,
              imgSrc: getAssetsFile(item.imgSrc, suffix),
              isFake: true,
            };
          });
          const num = 12 - this.teachersList.length;
          this.teachersList = this.teachersList.concat(
            assetsList.splice(0, num)
          );
        }
      }
    },
    // loaddata(page = 1) {
    //   const res = loadData(page);
    //   if (res) {
    //     this.teachersList = res.data.sort(() => Math.random() - 0.5);
    //     this.totle = res.total;
    //     this.page = res.page;
    //     this.pageSize = res.data.length;
    //   }
    // },
    handleCurrentChange(val) {
      this.loadAssets(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  min-height: 585px;
  margin-top: 20px;
}
.Grid {
  display: grid;
  grid-template-columns: 590px 590px;
  grid-template-rows: 150px;
  grid-gap: 0px 20px;
}
</style>
<style lang="scss">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f27200 !important;
}
</style>
