<template>
  <div>
    <div v-for="(item, index) in teachersList" :key="index">
      <div class="card flex" @click="test(item)">
        <img :src="item.imgSrc" alt class="img" />
        <div>
          <div class="name">{{ item.Name }}</div>
          <div class="tag flex">
            <span>课程{{ item.courseNum }} </span>|
            <span>粉丝{{ item.fans }}</span>
          </div>
          <div class="introduce">{{ item.introduce }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teachersList: {
      type: Array,
      default: () => [],
    },
    Show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    test(item) {
      this.$router.push({
        path: `/teachers/introduce/${item.id}/${item.isFake}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 590px;
  height: 130px;
  background: #f7f9fc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding-right: 24px;
  box-sizing: border-box;
  cursor: pointer;
  .name {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
  .img {
    margin: 0 21px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  .tag {
    width: 30%;
    margin: 10px 0;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    justify-content: space-between;
  }
  .introduce {
    font-size: 12px;
    line-height: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; // 控制多行的行数
    -webkit-box-orient: vertical;
  }
}
</style>
